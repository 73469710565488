import { Component, OnInit, Inject, ElementRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { DownloadService } from 'src/app/shared/Download.service';
import { from } from 'rxjs';
import { distinct } from 'rxjs/operators';

import { DanhMucQuanHuyen } from 'src/app/shared/DanhMucQuanHuyen.model';
import { DanhMucQuanHuyenService } from 'src/app/shared/DanhMucQuanHuyen.service';
import { DanhMucXaPhuong } from 'src/app/shared/DanhMucXaPhuong.model';
import { DanhMucXaPhuongService } from 'src/app/shared/DanhMucXaPhuong.service';
import { ToChucVungNuoiTinhTrangService } from '../shared/ToChucVungNuoiTinhTrang.service';

import { ToChuc } from 'src/app/shared/ToChuc.model';
import { ToChucService } from 'src/app/shared/ToChuc.service';

import * as maplibregl from 'maplibre-gl';

@Component({
  selector: 'app-ban-do005',
  templateUrl: './ban-do005.component.html',
  styleUrls: ['./ban-do005.component.css']
})
export class BanDo005Component implements OnInit {

  constructor(

    public NotificationService: NotificationService,
    public DownloadService: DownloadService,
    public DanhMucQuanHuyenService: DanhMucQuanHuyenService,
    public DanhMucXaPhuongService: DanhMucXaPhuongService,

    public ToChucService: ToChucService,
    public ToChucVungNuoiTinhTrangService : ToChucVungNuoiTinhTrangService

  ) { }

  ngOnInit(): void {
    this.ToChucService.BaseParameter.SearchString ="";
    this.DanhMucQuanHuyenSearch();
    this.DanhMucTrangThaiHoNuoi();
  }

  DanhMucQuanHuyenSearch() {
    if (this.DanhMucQuanHuyenService.List) {
      if (this.DanhMucQuanHuyenService.List.length == 0) {
        this.ToChucService.IsShowLoading = true;
        this.DanhMucQuanHuyenService.GetAllToListAsync().subscribe(
          res => {
            this.DanhMucQuanHuyenService.List = (res as DanhMucQuanHuyen[]).sort((a, b) => (a.Name > b.Name ? 1 : -1));
            this.DanhMucQuanHuyenService.ListFilter = this.DanhMucQuanHuyenService.List;
            this.DanhMucXaPhuongSearch();
          },
          err => {
          },
          () => {
            this.ToChucService.IsShowLoading = false;
          }
        );
      }
    }
  }
  DanhMucXaPhuongSearch() {
    this.ToChucService.IsShowLoading = true;
    this.DanhMucXaPhuongService.BaseParameter.ParentID = this.ToChucService.BaseParameter.DanhMucQuanHuyenID;
    if (this.ToChucService.BaseParameter.DanhMucQuanHuyenID == 0) {
      this.DanhMucXaPhuongService.BaseParameter.ParentID = 0;
      this.ToChucService.BaseParameter.DanhMucXaPhuongID = 0;
    }
    this.DanhMucXaPhuongService.GetByParentIDToListAsync().subscribe(
      res => {
        this.DanhMucXaPhuongService.List = (res as DanhMucXaPhuong[]).sort((a, b) => (a.Name > b.Name ? 1 : -1));
        if (this.DanhMucXaPhuongService.List.length > 0) {
          this.ToChucService.BaseParameter.DanhMucXaPhuongID = this.DanhMucXaPhuongService.List[0].ID;
        }
      },
      err => {
      },
      () => {
        this.ToChucService.IsShowLoading = false;
      }
    );
  }
  DanhMucTrangThaiHoNuoi(){
    this.ToChucVungNuoiTinhTrangService.ComponentGetAllToListAsync(this.ToChucService);
  }

  map: maplibregl.Map | undefined;

  @ViewChild('map')
  private mapContainer!: ElementRef<HTMLElement>;

  ngAfterViewInit() {

  }

  ngOnDestroy() {
    this.map?.remove();
  }

  MapInitialization(ID, longitude, latitude) {
    let IDDate = new Date().toISOString();
    let zoom = 11;
    if (this.ToChucService.BaseParameter.DanhMucXaPhuongID > 0) {
      zoom = 13;
    }
    if ((latitude > 90) || (latitude == 0)) {
      latitude = environment.Latitude;
      longitude = environment.Longitude;
    }

    if (ID == 0) {
      this.map = new maplibregl.Map({
        container: this.mapContainer.nativeElement,
        style: 'https://api.maptiler.com/maps/streets/style.json?key=' + environment.MaptilerAPIKey,
        center: [longitude, latitude],
        zoom: zoom,
        pitch: 45,
      });
    }
    if (ID == 1) {
      this.map = new maplibregl.Map({
        container: this.mapContainer.nativeElement,
        style: 'https://api.maptiler.com/maps/hybrid/style.json?key=' + environment.MaptilerAPIKey,
        center: [longitude, latitude],
        zoom: zoom,
        pitch: 45,
      });
    }

    this.map.addControl(
      new maplibregl.NavigationControl({
        visualizePitch: true,
        showZoom: true,
        showCompass: true
      })
    );
    this.map.addControl(
      new maplibregl.FullscreenControl({
      })
    );
    this.map.on('load', () => {

      this.map.addSource("HoangSa" + IDDate, {
        "type": "image",
        "url": environment.DomainURL + "assets/image/HoangSa01.png",
        "coordinates": [
          [111.09665858054495, 17.432475898867523],
          [113.11720985517763, 17.38420482529338],
          [112.79285037220984, 15.643938718432054],
          [110.88537855035554, 15.672592116966598],
        ]
      });
      this.map.addLayer({
        "id": "HoangSa" + IDDate,
        "source": "HoangSa" + IDDate,
        "type": "raster",
        "paint": {
          "raster-opacity": 1
        }
      });

      this.map.addSource("TruongSa" + IDDate, {
        "type": "image",
        "url": environment.DomainURL + "assets/image/TruongSa01.png",
        "coordinates": [
          [112.32373278444106, 12.236103169381323],
          [117.4620551483049, 11.606334626304161],
          [115.59654957671216, 7.357025445897818],
          [110.62186805246108, 7.811210355974268],


        ]
      });
      this.map.addLayer({
        "id": "TruongSa" + IDDate,
        "source": "TruongSa" + IDDate,
        "type": "raster",
        "paint": {
          "raster-opacity": 1
        }
      });

    });
  }

  MapLoad(ID: number) {
    this.ToChucService.IsShowLoading = true;
    this.ToChucService.BaseParameter.ParentID = environment.DanhMucToChucIDHoNuoi;
    this.ToChucService.BaseParameter.Active = true;
    this.ToChucService.GetSQLByParentID_Active_SearchString_DanhMucQuanHuyenID_DanhMucXaPhuongIDToListAsync().subscribe(
      res => {
        this.ToChucService.ListMap = (res as ToChuc[]);
        let longitude = environment.Longitude;
        let latitude = environment.Latitude;

        if (this.ToChucService.ListMap) {
          if (this.ToChucService.ListMap.length > 0) {
            longitude = this.DownloadService.GetKinhDo(Number(this.ToChucService.ListMap[0].KinhDo));
            latitude = this.DownloadService.GetViDo(Number(this.ToChucService.ListMap[0].ViDo));
          }
        }
        this.MapInitialization(ID, longitude, latitude);
        const backgroundImageMap: { [key: number]: string } = {
          3: "url(assets/image/Feed.png)",
          2: "url(assets/image/NotFeed.png)",
        };
        for (let i = 0; i < this.ToChucService.ListMap.length; i++) {

          let ToChuc = this.ToChucService.ListMap[i];

          let html = "<div style='background-color: #F9F4D5; border-radius: 10px; border: 1px solid #CDD3C1; padding: 15px; width: 400px; box-shadow: 2px 2px 10px rgba(0,0,0,0.1);'>";
          html += "<div style='border-bottom: 2px solid #9EC7F3; margin-bottom: 10px;'>";
          html += "<a style='cursor: pointer; text-decoration: none;' onclick='OpenWindowByToChuc(" + ToChuc.ID + ")'><h4 style='text-align: center; margin: 0; color: #0066CC; font-family: sans-serif;'><b>" + ToChuc.Name + "</b></h4></a>";
          html += "</div>";
          html += "<a style='cursor: pointer;' onclick='OpenWindowByToChuc(" + ToChuc.ID + ")'><h5 style='text-align: center; margin: 5px 0; font-size: 14px;'>Mã số: <b style='color: #0066CC;'>" + ToChuc.Code + " [" + ToChuc.ID + "]</b></h5></a>";

          html += "<table style='width: 100%; border-spacing: 0;'>";
          html += "<tr>";
          html += "<td style='width: 100%; vertical-align: top; font-size: 12px;'>";
             // Combine Loại Hình and Trạng Thái Vùng Nuôi in the same row
             html += "<div style='padding: 2px; display: flex; justify-content: space-between; align-items: center;'>";
             // Trạng Thái Vùng Nuôi
             html += `<div style="display: flex; align-items: center;">
           <b style="font-size: 12px; margin-right: 5px; color: #0066CC;">Trạng Thái:</b>
           <span style="font-size: 12px;">
               ${ToChuc.ToChucVungNuoiTrangThaiID === 1 ? `✅ ${ToChuc.ToChucVungNuoiTrangThaiName}` :
                 ToChuc.ToChucVungNuoiTrangThaiID === 3 ? `✔️ ${ToChuc.ToChucVungNuoiTrangThaiName}` :
                   `❌ ${ToChuc.ToChucVungNuoiTrangThaiName}`}
           </span>
        </div>`;
             html += "</div>"; // End of combined row

          html += "<div style='padding: 2px;'><b>Loại Hình :</b> <b> Hộ Nuôi </b></div>";
          html += "<div style='padding: 2px;'><b>Mã Số : </b>" + ToChuc.Code + "</div>";
          html += "<div style='padding: 2px;'><b>Vị Trí : </b>" + ToChuc.DanhMucQuanHuyenName + " / " + ToChuc.DanhMucXaPhuongName + "</div>";
          html += "<div style='padding: 2px;'><b>Loại mẫu: </b>" + (ToChuc.Description ? ToChuc.Description : 'Dữ liệu đang cập nhật') + "</div>";
          html += "<div style='padding: 2px;'><b>Loại nuôi: </b>" + (ToChuc.DanhMucGiongName ? ToChuc.DanhMucGiongName : 'Dữ liệu đang cập nhật') + "</div>";
          html += "</td>";
          html += "<td style='width: 100%; vertical-align: top; font-size: 12px;'>";
          // Thêm các chi tiết bổ sung nếu cần cho bên phải
          html += "</td>";
          html += "</tr>";
          html += "</table>";
          html += "<div style='border-bottom: 2px solid #9EC7F3; margin-bottom: 10px;'>";
          html += "<a style='cursor: pointer; text-decoration: none;'><h4 style='text-align: center; margin: 0; color: #0066CC; font-family: sans-serif;'></h4></a>";
          html += "</div>";
       // Quang Trac
          // Bảng con chứa thông tin môi trường và chỉ số
          html += "<table style='width: 100%; border-spacing: 5px; text-align: center; font-size: 12px; background-color: #FFFCE6; border-collapse: collapse;'>";

          if (ToChuc.DanhSachThongSoMoiTruong.length === 0) {
            // Hiển thị dòng thông báo nếu chưa có dữ liệu
            html = html + "<tr>";
            html = html + "<td colspan='3' style='padding: 5px; text-align: center;'>Dữ liệu đang cập nhật...</td>";
            html = html + "</tr>";
          } else {
            // Nếu có dữ liệu, hiển thị danh sách thông số môi trường
            const ngayCapNhat = this.getDateValue(ToChuc.DanhSachThongSoMoiTruong, 1);
            const formattedDate = this.formatDateString(ngayCapNhat);
            html += `
     <tr>
         <td colspan="2" rowspan="2" class="highlight" style="padding: 8px; background-color: #F3F2E3; border-radius: 10px; border: 1px solid #CDD3C1; font-weight: bold; color: #0066CC;">
             Cập nhật <br> ${formattedDate}
         </td>
         <td style='padding: 8px; background-color: #F3F2E3; border-radius: 5px; border: 1px solid #CDD3C1;'>Ammonium</td>
         <td style='padding: 8px; background-color: #FFF; border-radius: 5px; border: 1px solid #CDD3C1;'>${this.getThongSoValue(ToChuc.DanhSachThongSoMoiTruong, 7)}</td>
         <td style='padding: 8px; background-color: #F3F2E3; border-radius: 5px; border: 1px solid #CDD3C1;'>Độ kiềm</td>
         <td style='padding: 8px; background-color: #FFF; border-radius: 5px; border: 1px solid #CDD3C1;'>${this.getThongSoValue(ToChuc.DanhSachThongSoMoiTruong, 2)}</td>
     </tr>
     <tr>
         <td style='padding: 8px; background-color: #F3F2E3; border-radius: 5px; border: 1px solid #CDD3C1;'>Cd</td>
         <td style='padding: 8px; background-color: #FFF; border-radius: 5px; border: 1px solid #CDD3C1;'>${this.getThongSoValue(ToChuc.DanhSachThongSoMoiTruong, 11)}</td>
         <td style='padding: 8px; background-color: #F3F2E3; border-radius: 5px; border: 1px solid #CDD3C1;'>Độ mặn</td>
         <td style='padding: 8px; background-color: #FFF; border-radius: 5px; border: 1px solid #CDD3C1;'>${this.getThongSoValue(ToChuc.DanhSachThongSoMoiTruong, 3)}</td>
     </tr>
     <tr>
         <td style='padding: 8px; background-color: #F3F2E3; border-radius: 5px; border: 1px solid #CDD3C1;'>Vibrio spp.</td>
         <td style='padding: 8px; background-color: #FFF; border-radius: 5px; border: 1px solid #CDD3C1;'>${this.getThongSoValue(ToChuc.DanhSachThongSoMoiTruong, 4)}</td>
         <td style='padding: 8px; background-color: #F3F2E3; border-radius: 5px; border: 1px solid #CDD3C1;'>DO</td>
         <td style='padding: 8px; background-color: #FFF; border-radius: 5px; border: 1px solid #CDD3C1;'>${this.getThongSoValue(ToChuc.DanhSachThongSoMoiTruong, 8)}</td>
         <td style='padding: 8px; background-color: #F3F2E3; border-radius: 5px; border: 1px solid #CDD3C1;'>Độ pH</td>
         <td style='padding: 8px; background-color: #FFF; border-radius: 5px; border: 1px solid #CDD3C1;'>${this.getThongSoValue(ToChuc.DanhSachThongSoMoiTruong, 1)}</td>
     </tr>
     <tr>
         <td style='padding: 8px; background-color: #F3F2E3; border-radius: 5px; border: 1px solid #CDD3C1;'>Vibrio PS</td>
         <td style='padding: 8px; background-color: #FFF; border-radius: 5px; border: 1px solid #CDD3C1;'>${this.getThongSoValue(ToChuc.DanhSachThongSoMoiTruong, 5)}</td>
         <td style='padding: 8px; background-color: #F3F2E3; border-radius: 5px; border: 1px solid #CDD3C1;'>TSS</td>
         <td style='padding: 8px; background-color: #FFF; border-radius: 5px; border: 1px solid #CDD3C1;'>${this.getThongSoValue(ToChuc.DanhSachThongSoMoiTruong, 10)}</td>
         <td style='padding: 8px; background-color: #F3F2E3; border-radius: 5px; border: 1px solid #CDD3C1;'>Nhiệt độ</td>
         <td style='padding: 8px; background-color: #FFF; border-radius: 5px; border: 1px solid #CDD3C1;'>${this.getThongSoValue(ToChuc.DanhSachThongSoMoiTruong, 12)}</td>
     </tr>
     <tr>
         <td style='padding: 8px; background-color: #F3F2E3; border-radius: 5px; border: 1px solid #CDD3C1;'>Aeromonas</td>
         <td style='padding: 8px; background-color: #FFF; border-radius: 5px; border: 1px solid #CDD3C1;'>${this.getThongSoValue(ToChuc.DanhSachThongSoMoiTruong, 6)}</td>
         <td style='padding: 8px; background-color: #F3F2E3; border-radius: 5px; border: 1px solid #CDD3C1;'>COD</td>
         <td style='padding: 8px; background-color: #FFF; border-radius: 5px; border: 1px solid #CDD3C1;'>${this.getThongSoValue(ToChuc.DanhSachThongSoMoiTruong, 9)}</td>
         <td style='padding: 8px; background-color: #F3F2E3; border-radius: 5px; border: 1px solid #CDD3C1;'>Độ trong</td>
         <td style='padding: 8px; background-color: #FFF; border-radius: 5px; border: 1px solid #CDD3C1;'>${this.getThongSoValue(ToChuc.DanhSachThongSoMoiTruong, 13)}</td>
     </tr>
     `;
          }
          html += "</table>";
          // Button 
          html += `<div style="text-align: center; margin-top: 10px; display: flex; justify-content: space-around;">
                  <button id='directionsButton' style='padding: 8px 15px; background-color: #F3F2E3; border-radius: 10px; border: none; cursor: pointer; display: inline-flex; align-items: center; box-shadow: 0 2px 5px rgba(0,0,0,0.2);'>
                      <span style="color: #007BFF; font-size: 14px; margin-left: 8px;">Xem Lộ Trình</span>
                  </button>

                  <button id='detailsButton' onclick='OpenWindowByToChuc(${ToChuc.ID})' style='padding: 8px 15px; background-color: #F3F2E3; border-radius: 10px; border: none; cursor: pointer; display: inline-flex; align-items: center; box-shadow: 0 2px 5px rgba(0,0,0,0.2);'>
                      <span style="color: #007BFF; font-size: 14px; margin-left: 8px;">Xem Chi Tiết</span>
                  </button>
              </div>`;
          // Note
          html += `<div style="text-align: center; margin-top: 10px; display: flex; justify-content: space-around;">
    <div style="padding: 8px 15px; background-color: #CCCCCC; display: inline-flex; align-items: center; box-shadow: 0 2px 5px rgba(0,0,0,0.2); opacity: 0.5;">
        <span style="color: #007BFF; font-size: 14px; font-weight: bold;">KPH:</span>
        <span style="color: #007BFF; font-size: 14px; margin-left: 5px;">không phát hiện</span>
    </div>

    <div style="padding: 8px 15px; background-color: #CCCCCC; display: inline-flex; align-items: center; box-shadow: 0 2px 5px rgba(0,0,0,0.2); opacity: 0.5;">
        <span style="color: #007BFF; font-size: 14px; font-weight: bold;">KPT:</span>
        <span style="color: #007BFF; font-size: 14px; margin-left: 5px;">không phân tích</span>
    </div>
</div>
`;
          html += "</div>";

          let popup = new maplibregl.Popup({ offset: 25 }).setHTML(html)
            .setMaxWidth("600px");

          longitude = this.DownloadService.GetKinhDo(Number(ToChuc.KinhDo));
          latitude = this.DownloadService.GetViDo(Number(ToChuc.ViDo));
          var el = document.createElement('div');
       
          el.style.backgroundImage = backgroundImageMap[ToChuc.ToChucVungNuoiTrangThaiID] || "url(assets/image/IconHoNuoi.png)";
          el.style.width = '30px';
          el.style.height = '42px';
          let marker = new maplibregl.Marker({ element: el })
            .setLngLat([longitude, latitude])
            .setPopup(popup)
            .addTo(this.map);
          popup.on('open', () => {
            const directionsButton = document.getElementById('directionsButton');
            if (directionsButton) {
              directionsButton.addEventListener('click', () => {
                this.getDirections(longitude, latitude);
              });
            }
          });

        }


      },
      err => {
      },
      () => {
        this.ToChucService.IsShowLoading = false;
      },
    );
  }

  getThongSoValue(danhSachThongSoMoiTruong: any[], id: number): string {
    const thongSo = danhSachThongSoMoiTruong.find(ts => ts.DanhMucChiTieuMoiTruongID === id);
    return thongSo ? `${thongSo.SoLieu} ` : 'N/A';
  }
//${thongSo.DonViTinh}
  getDirections(longitude: number, latitude: number) {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function (position) {
        const currentLatitude = position.coords.latitude;
        const currentLongitude = position.coords.longitude;

        // Mở Google Maps với đường đi từ vị trí hiện tại đến vị trí trạm quan trắc
        const url = `https://www.google.com/maps/dir/${currentLatitude},${currentLongitude}/${latitude},${longitude}`;
        window.open(url, '_blank');
      }, function (error) {
        alert('Không thể xác định vị trí của bạn.');
      });
    } else {
      alert('Trình duyệt của bạn không hỗ trợ định vị địa lý.');
    }
  }
  
  getDateValue(danhSachThongSoMoiTruong: any[], id: number): string {
    const thongSo = danhSachThongSoMoiTruong.find(ts => ts.DanhMucChiTieuMoiTruongID === id);
    return thongSo ? `${thongSo.NgayGhiNhan} ` : new Date().toLocaleDateString();
  }

  formatDateString(dateString: string): string {
    if (!dateString) return '';

    // Tạo đối tượng Date từ chuỗi (nếu định dạng hợp lệ)
    const date = new Date(dateString);

    // Kiểm tra xem Date có hợp lệ không
    if (isNaN(date.getTime())) {
      // Nếu chuỗi không phải định dạng chuẩn, cắt và xử lý chuỗi
      const parts = dateString.split(/[-T\s:]/); // Tách năm, tháng, ngày
      if (parts.length >= 3) {
        const year = parts[0];
        const month = parts[1].padStart(2, '0'); // Đảm bảo tháng luôn có 2 chữ số
        const day = parts[2].padStart(2, '0');   // Đảm bảo ngày luôn có 2 chữ số
        return `${day}/${month}/${year}`;
      }
      return ''; // Trả về chuỗi rỗng nếu không thể xử lý
    }

    // Format ngày, tháng, năm
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Tháng trong JS bắt đầu từ 0
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }
}