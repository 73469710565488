import { Component, OnInit, Inject, ElementRef, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';


import { UploadService } from 'src/app/shared/Upload.service';


@Component({
  selector: 'app-uploadmoitruong',
  templateUrl: './uploadmoitruong.component.html',
  styleUrls: ['./uploadmoitruong.component.css']
})
export class UploadmoitruongComponent implements OnInit {

  
  IsFile: boolean = false;
  URLExcel: string = environment.APIUploadRootURL + environment.Download + "/ChiTieuMoiTruong.xlsx"; 
  constructor(
    public NotificationService: NotificationService,

    public UploadService: UploadService,

  ) { }

  ngOnInit(): void {
  }

  
  ChangeFile(files: FileList) {
    if (files) {
      this.UploadService.FileToUpload = files;
      this.IsFile = true;
    }
  }
  Upload() {
     
    this.UploadService.IsShowLoading = true;    
    this.UploadService.UploadChiTieuMoiTruongTracExcelFileAsync().subscribe(
      res => {        
        this.NotificationService.warn(environment.UploadSuccess);
       
      },
      err => {
        this.NotificationService.warn(environment.UploadNotSuccess);
       
      },
      ()=>{
        this.UploadService.IsShowLoading = false;
      }
    );
  }
}
